import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "m-2 cursor-pointer" }
const _hoisted_2 = {
  key: 0,
  class: "bg-ph-background-3 dark:bg-ph-background-5 hover:bg-blue p-4 rounded-md relative text-center overflow-hidden"
}

import { computed } from 'vue';
	import { Hub } from '@/store/store';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubBlock',
  props: {
    hub: {}
  },
  setup(__props: any) {

	const props = __props;

	const description = computed(() => {
		if (props.hub.description !== '') {
			return props.hub.description;
		}
		return props.hub.hubId;
	});

return (_ctx: any,_cache: any) => {
  const _component_H3 = _resolveComponent("H3")!
  const _component_HubLogo = _resolveComponent("HubLogo")!
  const _component_TruncatedText = _resolveComponent("TruncatedText")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hub)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_H3, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.hub.hubId), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_HubLogo, {
            "hub-id": _ctx.hub.hubId,
            "hub-url": _ctx.hub.url,
            "change-to-dark": true,
            class: "h-16 w-16 mx-auto"
          }, null, 8, ["hub-id", "hub-url"]),
          _createVNode(_component_TruncatedText, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(description.value), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})