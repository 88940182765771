import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 0 }

import { useGlobal, useHubs } from '@/store/store';
	import { useToggleMenu } from '@/store/toggleGlobalMenu';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'HubMenu',
  setup(__props) {

	const global = useGlobal();
	const hubs = useHubs();
	const toggleMenu = useToggleMenu();

return (_ctx: any,_cache: any) => {
  const _component_HubIcon = _resolveComponent("HubIcon")!
  const _component_Line = _resolveComponent("Line")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_unref(hubs).currentHubId && !_unref(global).existsInPinnedHubs(_unref(hubs).currentHubId))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_HubIcon, {
            class: "text-black",
            hub: _unref(hubs).currentHub,
            active: true,
            pinnable: _unref(global).loggedIn,
            onPin: _cache[0] || (_cache[0] = ($event: any) => (_unref(global).addPinnedHub(_unref(hubs).currentHub, 0))),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(toggleMenu).hideMenuAndSendToHub()))
          }, null, 8, ["hub", "pinnable"]),
          (_unref(global).hasPinnedHubs)
            ? (_openBlock(), _createBlock(_component_Line, {
                key: 0,
                class: "m-6 mt-8"
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(global).pinnedHubs, (element, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: element.hubId
      }, [
        _createVNode(_component_router_link, {
          to: { name: 'hub', params: { id: element.hubId } }
        }, {
          default: _withCtx(({ isActive }) => [
            (_unref(global).loggedIn || element.hubId === _unref(hubs).currentHubId)
              ? (_openBlock(), _createBlock(_component_HubIcon, {
                  key: 0,
                  class: "text-ph-text",
                  hub: _unref(hubs).hub(element.hubId),
                  active: isActive,
                  pinned: true,
                  onRemove: ($event: any) => (_unref(global).removePinnedHub(index)),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(toggleMenu).hideMenuAndSendToHub()))
                }, null, 8, ["hub", "active", "onRemove"]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1032, ["to"])
      ]))
    }), 128))
  ], 64))
}
}

})