import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = { key: 0 }


export default /*@__PURE__*/_defineComponent({
  __name: 'HubIcon',
  props: {
		type: {
			type: String,
			default: 'circle',
		},
		size: {
			type: String,
			default: '3xl',
		},
		hub: {
			type: Object,
			default: undefined,
		},
		pinned: {
			type: Boolean,
			default: false,
		},
		pinnable: {
			type: Boolean,
			default: false,
		},
		active: {
			type: Boolean,
			default: false,
		},
	},
  emits: ['pin', 'remove'],
  setup(__props, { emit: __emit }) {

	const props = __props;

	const emit = __emit;

	function pin() {
		emit('pin');
	}

	function remove() {
		emit('remove');
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_HubLogo = _resolveComponent("HubLogo")!

  return (_openBlock(), _createElementBlock("div", {
    class: "z-0 block group text-center w-24 h-24 p-2 m-2 cursor-pointer relative overflow-hidden",
    title: __props.hub ? __props.hub.hubId : null
  }, [
    (__props.hub && __props.hub.unreadMessages > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("span", { class: "absolute flex h-3 w-3 left-5" }, [
            _createElementVNode("span", { class: "animate-ping absolute inline-flex h-full w-full rounded-full bg-notification opacity-75" }),
            _createElementVNode("span", { class: "absolute inline-flex rounded-full h-3 w-3 bg-notification" })
          ], -1)
        ])))
      : _createCommentVNode("", true),
    (__props.pinnable)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 1,
          type: "plus",
          class: "fill-green opacity-60 hover:opacity-100 absolute right-0 top-0",
          onClick: _withModifiers(pin, ["prevent"])
        }))
      : _createCommentVNode("", true),
    (__props.pinned)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 2,
          type: "remove",
          class: "fill-red-light absolute right-0 top-0 hidden opacity-60 hover:opacity-100 group-hover:block",
          onClick: _withModifiers(remove, ["prevent"])
        }))
      : _createCommentVNode("", true),
    (__props.hub)
      ? (_openBlock(), _createBlock(_component_HubLogo, {
          key: 3,
          "hub-url": __props.hub.url,
          "hub-id": __props.hub.hubId,
          "change-to-dark": false,
          class: "h-20 w-20 mx-auto rounded-full"
        }, null, 8, ["hub-url", "hub-id"]))
      : _createCommentVNode("", true),
    _createVNode(_component_Icon, {
      type: "speech_bubble",
      size: '4xl',
      class: _normalizeClass(["absolute top-0 left-[3px] m-auto -z-10 stroke-none opacity-0 group-hover:opacity-60", { 'opacity-100': __props.active }])
    }, null, 8, ["class"])
  ], 8, _hoisted_1))
}
}

})