import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from 'vue';
	import { buttonSizes } from '@/assets/sizes';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'Button',
  props: {
		color: {
			type: String,
			default: 'blue',
		},
		size: {
			type: String,
			default: 'base',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
  setup(__props) {

	const colorClass: { [key: string]: string } = {
		disabled: 'bg-gray-light text-white',
		white: 'bg-white hover:bg-blue text-black shadow-md cursor-pointer',
		'gray-light': 'bg-gray-light hover:bg-blue text-white shadow-md cursor-pointer',
		blue: 'bg-blue hover:bg-blue-dark text-white dark:hover:bg-white dark:hover:text-blue-dark shadow-md cursor-pointer',
		green: 'bg-green hover:bg-green-dark text-white shadow-md cursor-pointer',
		red: 'bg-red hover:bg-red-dark text-white shadow-md cursor-pointer',
		black: 'bg-black hover:bg-gray-dark text-white shadow-md cursor-pointer',
	};

	const props = __props;

	const buttonClass = computed(() => {
		let c = buttonSizes[props.size] + ' ';
		if (props.disabled) {
			c += colorClass['disabled'];
		} else {
			c += colorClass[props.color];
		}
		return c;
	});

	function click(event: Event) {
		if (props.disabled) {
			event.stopImmediatePropagation();
		}
	}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([buttonClass.value, "block font-semibold rounded-lg focus:outline-none focus:ring-2 focus:ring-opacity-75 text-center"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (click($event)))
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})