import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between h-full" }
const _hoisted_2 = { class: "flex-1 text-center w-fit mx-auto" }
const _hoisted_3 = { class: "mx-6" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "flex justify-between" }
const _hoisted_6 = ["href"]

import { useDialog, useGlobal } from '@/store/store';
	import { useToggleMenu } from '@/store/toggleGlobalMenu';
	import { ref } from 'vue';
	import { useI18n } from 'vue-i18n';

	
export default /*@__PURE__*/_defineComponent({
  __name: 'GlobalBar',
  setup(__props) {

	const dialog = useDialog();
	const settingsDialog = ref(false);
	const { t } = useI18n();
	const global = useGlobal();
	const toggleMenu = useToggleMenu();

	// eslint-disable-next-line
	const pubHubsUrl = _env.PUBHUBS_URL;

	async function logout() {
		if (await dialog.yesno(t('logout.logout_sure'))) {
			global.logout();
		}
	}

return (_ctx: any,_cache: any) => {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Line = _resolveComponent("Line")!
  const _component_HubMenu = _resolveComponent("HubMenu")!
  const _component_SettingsDialog = _resolveComponent("SettingsDialog")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", {
    id: "pubhubs-bar",
    class: _normalizeClass(["flex-none w-32 bg-ph-background-3 dark:bg-ph-background-5 h-full pt-20 2md:pt-2 2md:block", { hidden: !_unref(toggleMenu).globalIsActive }])
  }, [
    _createVNode(_component_Modal, {
      show: _unref(global).isModalVisible
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createVNode(_component_Icon, {
                  type: "pubhubs-home",
                  size: "3xl",
                  class: "text-white mx-auto",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(toggleMenu).hideMenuAndSendToHub()))
                })
              ]),
              _: 1
            }),
            (_unref(global).loggedIn && _unref(global).hasPinnedHubs)
              ? (_openBlock(), _createBlock(_component_Line, {
                  key: 0,
                  class: "m-6 mt-8"
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_HubMenu),
            (_unref(global).loggedIn)
              ? (_openBlock(), _createBlock(_component_Line, {
                  key: 1,
                  class: "m-6 mt-8"
                }))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_unref(global).loggedIn)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (settingsDialog.value)
                    ? (_openBlock(), _createBlock(_component_SettingsDialog, {
                        key: 0,
                        onClose: _cache[1] || (_cache[1] = ($event: any) => (settingsDialog.value = false))
                      }))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_Icon, {
                      type: "cog",
                      class: "text-dark",
                      size: "lg",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (settingsDialog.value = true))
                    }),
                    _createVNode(_component_Icon, {
                      type: "power",
                      class: "text-dark",
                      size: "lg",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (logout()))
                    })
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("a", {
              href: _unref(pubHubsUrl),
              class: "m-2 sm:m-4"
            }, [
              _createVNode(_component_Logo, { global: true })
            ], 8, _hoisted_6)
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ], 2))
}
}

})