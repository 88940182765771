<template>
	<h1 v-tw-class="'text-xl md:text-2xl light:text-gray theme-light:text-gray'">
		<slot></slot>
	</h1>
</template>

<style scoped>
	h1 {
		font-family: 'work_sanssemibold';
	}
</style>
